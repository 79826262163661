import React, { useState, useEffect, forwardRef } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// Resuqests
import { GraphQLClient } from 'graphql-request';
import Constants from '../Constants';
// Navigation
import { Navigate } from 'react-router-dom';
// Images
import logo from '../assets/logo.png';

// Set theme
const defaultTheme = createTheme();

// Set notification alert
const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Login() {
  // Dynamic variables
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertKind, setAlertKind] = useState("success");
  const [redirectTo, setRedirectTo] = useState("/login");
  const [redirect, setRedirect] = useState(false);
  // Visitapp App Kind
  const [appKind, setAppKind] = useState("company");
  
  // Set App Kind
  useEffect(() => {
    // Get domain
    if (window.location.hostname === 'localhost2' || window.location.hostname === 'sales.visitapp.io') {
      setAppKind('residential');
    }
  }, [])

  // Show alert
  const handleShowAlert = (message, kind) => {
    setAlertMessage(message);
    setShowAlert(true);
    setAlertKind(kind);
  }

  // Submit login form
  const handleSubmit = async (event) => {
    event.preventDefault();
    // Show loading
    setLoading(true);

    try {
      const formData = new FormData(event.currentTarget);
      // Set form data
      const username = formData.get('username');
      const password = formData.get('password');
      const tenant = appKind === 'company' ? formData.get('residential') : 'public';
  
      // Request options
      const client = new GraphQLClient(Constants.serverUrl);
      const query = `mutation { login(login: { brand: "web", carrier: "3g", channel: "${tenant}", country: "mexico", deviceName: "device", idDevice: "web", ip: "na", password: "${password}", username: "${username}" }) { channel,representative,rol,token }}`;
      // Make request
      const req = await client.request(query);
      // console.log(req.login);
      // Validate rol
      if (req.login?.rol === "Colono" || req.login?.rol === "Supervisor") {
        // Set session data
        sessionStorage.setItem("username", username);
        sessionStorage.setItem("token", req.login?.token);
        sessionStorage.setItem("rol", req.login?.rol);
        // Hide loading
        setLoading(false);
        // Redirect
        setRedirectTo("/visits");
        setRedirect(true);
      } else {
        setLoading(false);
        handleShowAlert("Tu usuario no cuenta con permisos para esta plataforma", "error");
      }
    } catch(err) {
      // console.log(err);
      handleShowAlert("Credenciales de acceso inválidas", "error");
      setLoading(false);
    }
  };
  

  // UI
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="md">
        {/* Redirect */}
        {redirect ? <Navigate to={redirectTo} replace={true} /> : null}
        {/* Notification / Alert */}
        <Snackbar open={showAlert} autoHideDuration={5000} onClose={() => setShowAlert(false)}>
          <Alert onClose={() => setShowAlert(false)} severity={alertKind} sx={{ width: '100%' }}>{alertMessage}</Alert>
        </Snackbar>
        {/* Loaging */}
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}> <CircularProgress color="inherit" /> </Backdrop>
        {/* Line */}
        <CssBaseline />

        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={logo} className="App-logo" alt="Visitapp" style={{width: 250, marginBottom: 40}} />
          
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{width: '60%', p: 5, border: '1px solid #ddd', borderRadius: 5}}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Usuario"
              name="username"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              autoComplete="current-password"
            />

            {appKind === 'company' ?
              <TextField
                margin="normal"
                required
                fullWidth
                name="residential"
                label="Empresa"
                autoComplete="current-password"
              />
            : null}

            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Recordarme"
              name="remember"
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Acceder
            </Button>

            {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  ¿Olvidaste tu contraseña?
                </Link>
              </Grid>
            </Grid> */}

          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}