import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';
import Login from './pages/login';
import Visits from './pages/visits';
import PendingVisits from './pages/pendingVisits';
import VisitQr from './pages/visitQr';

const router = createBrowserRouter([
  {path: "/", element: <Login/>},
  {path: "/login", element: <Login/>},
  {path: "/visits", element: <Visits/>},
  {path: "/pending-visits", element: <PendingVisits/>},
  {path: "/visit-qr/:id", element: <VisitQr/>},
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
