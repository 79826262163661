import * as React from 'react';
import { Drawer, Box, List, ListItem, ListItemIcon, ListItemButton, ListItemText, ImageList, ImageListItem, Divider, Modal } from '@mui/material';
// Icons
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import BusinessIcon from '@mui/icons-material/Business';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import PersonIcon from '@mui/icons-material/Person';
import CameraFrontIcon from '@mui/icons-material/CameraFront';
// moment
import moment from 'moment';
// Constants
import Constants from '../Constants';
// Styles
const modalStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function VisitDetails(props) {
  // Visit photos
  const [visitPhoto, setVisitPhoto] = React.useState('');
  const [visitPhotoModal, setVisitPhotoModal] = React.useState(false);

  const getPermanence = (time) => {
    const hrs = Math.floor(time/60);
    const mins = (time/60) - Math.floor(time/60);
    return `${hrs} hrs ${mins.toFixed(2).toString().split('.')[1]} min`;
  }

  const openPhoto = (url) => {
    setVisitPhoto(url);
    setVisitPhotoModal(true);
  }

  return (
    <div>
      {/* Visit photos modal */}
      <Modal
        open={visitPhotoModal}
        onClose={() => setVisitPhotoModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyles}>
          <img src={visitPhoto} alt="Visita" style={{width: '100%'}} />
        </Box>
      </Modal>

      <Drawer open={props.open} onClose={() => props.handleClose()}>
        <Box
          sx={{ width: '30vw' }}
          role="presentation" 
          
        >
          <List>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><FindInPageIcon /></ListItemIcon>
                <ListItemText style={{textTransform: 'capitalize'}} primary={props.visit.complement?.subject ? props.visit.complement?.subject : props.visit.subject} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><SubtitlesIcon /></ListItemIcon>
                <ListItemText primary={props.visit.folio} />
              </ListItemButton>
            </ListItem>

            {props.visit.photos ? 
              <>
                <Divider />
                <ListItem disablePadding style={{paddingTop: 15}}>
                  <ImageList sx={{ width: '100%', height: 100 }} cols={3} rowHeight={20}>
                    {props.visit.photos.map((photo) => {
                      return (
                        <ImageListItem key={photo.url} onClick={() => openPhoto(photo.url)}>
                          <img src={photo.url} loading="lazy" alt="Foto" style={{margin: '0px 2px'}} />
                        </ImageListItem>
                      )
                    })}
                  </ImageList>
                </ListItem>
              </>
            : null}
          </List>
          
          <Divider />
          
          <List>
            {props.visit.complement?.companyName ? 
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><BusinessIcon /></ListItemIcon>
                <ListItemText style={{textTransform: 'capitalize'}} primary={`Empresa ${props.visit.complement?.companyName}`} />
              </ListItemButton>
            </ListItem>
            : null}
            {props.visit.complement?.campus ? 
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><BusinessIcon /></ListItemIcon>
                <ListItemText style={{textTransform: 'capitalize'}} primary={`Sede ${props.visit.complement?.campus}`} />
              </ListItemButton>
            </ListItem>
            : null}
            {props.visit.complement?.visitKind ? 
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><CameraFrontIcon /></ListItemIcon>
                <ListItemText style={{textTransform: 'capitalize'}} primary={props.visit.complement?.visitKind} />
              </ListItemButton>
            </ListItem>
            : null}
            {props.visit.complement?.details ? 
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><FindInPageIcon /></ListItemIcon>
                <ListItemText style={{textTransform: 'capitalize'}} primary={props.visit.complement?.details} />
              </ListItemButton>
            </ListItem>
            : null}
            {props.visit.complement?.driver ? 
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><PersonIcon /></ListItemIcon>
                <ListItemText style={{textTransform: 'capitalize'}} primary={`Conductor ${props.visit.complement?.driver}`} />
              </ListItemButton>
            </ListItem>
            : null}
            {props.visit.complement?.vehicleColor ? 
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><DriveEtaIcon /></ListItemIcon>
                <ListItemText style={{textTransform: 'capitalize'}} primary={props.visit.complement?.vehicleColor} />
              </ListItemButton>
            </ListItem>
            : null}
            {props.visit.complement?.vehicleKind ? 
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><DriveEtaIcon /></ListItemIcon>
                <ListItemText style={{textTransform: 'capitalize'}} primary={props.visit.complement?.vehicleKind} />
              </ListItemButton>
            </ListItem>
            : null}
            {props.visit.complement?.vehiclePlates ? 
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><DriveEtaIcon /></ListItemIcon>
                <ListItemText style={{textTransform: 'capitalize'}} primary={props.visit.complement?.vehiclePlates} />
              </ListItemButton>
            </ListItem>
            : null}
          </List>
          
          <Divider />

          <List>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><CalendarMonthIcon /></ListItemIcon>
                <ListItemText style={{textTransform: 'capitalize'}} primary={`Programada: ${props.visit.dueDate ? moment(props.visit.dueDate).format("DD-MM-YYYY HH:mm") : 'Pendiente'}`} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><CalendarMonthIcon /></ListItemIcon>
                <ListItemText style={{textTransform: 'capitalize'}} primary={`Llegada: ${props.visit.arriveDate ? moment(props.visit.arriveDate).format("DD-MM-YYYY HH:mm") : 'Pendiente'}`} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><CalendarMonthIcon /></ListItemIcon>
                <ListItemText style={{textTransform: 'capitalize'}} primary={`Entrada: ${props.visit.enterDate ? moment(props.visit.enterDate).format("DD-MM-YYYY HH:mm") : 'Pendiente'}`} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><CalendarMonthIcon /></ListItemIcon>
                <ListItemText style={{textTransform: 'capitalize'}} primary={`Salida: ${props.visit.leaveDate ? moment(props.visit.leaveDate).format("DD-MM-YYYY HH:mm") : 'Pendiente'}`} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><CalendarMonthIcon /></ListItemIcon>
                <ListItemText style={{textTransform: 'capitalize'}} primary={`Permanencia: ${props.visit.permanence ? getPermanence(props.visit.permanence) : 'Pendiente'}`} />
              </ListItemButton>
            </ListItem>
          </List>

          <Divider />

          <List>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><TextSnippetIcon /></ListItemIcon>
                <ListItemText style={{textTransform: 'capitalize'}} primary={`Creada por guardia: ${props.visit.quick ? "Si" : 'No'}`} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><TextSnippetIcon /></ListItemIcon>
                <ListItemText style={{textTransform: 'capitalize'}} primary={`Status: ${props.visit.status ? Constants.getStatus(props.visit.status) : ''}`} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><TextSnippetIcon /></ListItemIcon>
                <ListItemText style={{textTransform: 'capitalize'}} primary={`Creada por: ${props.visit.user?.name ? props.visit.user?.name : ''}`} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><TextSnippetIcon /></ListItemIcon>
                <ListItemText style={{textTransform: 'capitalize'}} primary={`Departamento: ${props.visit.house?.address ? props.visit.house?.address : ''}`} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><TextSnippetIcon /></ListItemIcon>
                <ListItemText style={{textTransform: 'capitalize'}} primary={`Reporte de staff: ${props.visit.residentReport ? props.visit.residentReport : 'No'}`} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon><TextSnippetIcon /></ListItemIcon>
                <ListItemText style={{textTransform: 'capitalize'}} primary={`Incidentes: ${props.visit.insidents?.reason ? props.visit.insidents?.reason : ''}`} />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </div>
  );
}
