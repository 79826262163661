import * as React from 'react';
import TopMenu from '../components/topMenu';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Container, Typography, Avatar, Button, Chip, Stack, TextField, DialogContentText, DialogContent, DialogActions, Pagination } from '@mui/material';
// Card
import { Card, CardContent } from '@mui/material';
// List
import { List, ListItem, ListItemAvatar } from '@mui/material';
// Date range picker
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
// Navigation & loading
import { Navigate } from 'react-router-dom';
import { Snackbar, Backdrop, CircularProgress } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
// Requests
import { GraphQLClient } from 'graphql-request';
import Constants from '../Constants';
// Dialog alert
import { Dialog, DialogTitle } from '@mui/material';
// Icons
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import BusinessIcon from '@mui/icons-material/Business';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import PersonIcon from '@mui/icons-material/Person';
import CameraFrontIcon from '@mui/icons-material/CameraFront';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import CircleIcon from '@mui/icons-material/Circle';
import LocationOnIcon from '@mui/icons-material/LocationOn';
// Visit details
import VisitDetails from '../components/visitDetails';
import NewVisit from '../components/newVisit';

// Styles 
const dateInput = {
  font: 'inherit',
  boxSizing: 'content-box',
  background: 'none',
  width: '25%',
  border: '1px solid #ccc',
  borderRadius: 5,
  padding: '10px 20px'
}
// Set theme
const defaultTheme = createTheme();
// Session data
let token = null;
// Date
const today = new Date();
// Set notification alert
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Visits() {
  // Filters
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const [page, setPage] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [search, setSearch] = React.useState("");
  // General
  const [redirect, setRedirect] = React.useState(false);
  const [redirectTo, setRedirectTo] = React.useState('/login');
  const [loading, setLoading] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertKind, setAlertKind] = React.useState("success");
  // Residential
  const [residential, setResidential] = React.useState({});
  // Visits
  const [visitDetailsDrawer, setVisitDetailsDrawer] = React.useState(false);
  const [visits, setVisits] = React.useState([]);
  const [visitDetails, setVisitDetails] = React.useState({});
  const [openBadCompany, setBadCompany] = React.useState(false);
  const [rol, setRol] = React.useState(null);
  // Visit status
  const [openVisitStatus, setOpenVisitStatus] = React.useState(false);
  const [newVisitStatus, setNewVisitStatus] = React.useState('');
  // New visit
  const [newVisitModal, setNewVisitModal] = React.useState(false);
  // Component mount
  let mount = false;

  // Get sessión data
  React.useEffect(() => {
    if (!mount) {
      token = sessionStorage.getItem('token');
      setRol( sessionStorage.getItem('rol'));
      // Redirect to login
      if (!token) {
        setRedirectTo('/login');
        setRedirect(true);
      } 
      
      // Update mount
      mount = true;

      // Get residential
      getResidential();
      // Get visits
      getVisits();
    }
  }, [])

   // Show alert
   const handleShowAlert = (message, kind) => {
    setAlertMessage(message);
    setShowAlert(true);
    setAlertKind(kind);
  }

  // Get residential
  const getResidential = async () => {
    setLoading(true);
    try {
      // Request options
      const client = new GraphQLClient(Constants.serverUrl, {headers: { Authorization: `Bearer: ${token}` }});
      const query = `{ myResidential { name, guardInVisits, qrNumeric, hideQrForConstruction, company, timezoneHours, timelineVisitTypesList, staffVisitTypesList, timelineVehicleTypeList, companyCampus } }`;

      // Make request
      const req = await client.request(query);
      // console.log(req);
      setLoading(false);

      // Validate company
      if (req.myResidential?.company) {
        // Update residential
        setResidential(req.myResidential);
      } else {
        // Show alert
        setBadCompany(true);
      }
    } catch(err) {
      console.log(err);
      handleShowAlert("No se pudo obtener información del residencial", "error");
      setLoading(false);
    }
  }

  // Get visits
  const getVisits = async () => {
    try {
      // Request options
      const client = new GraphQLClient(Constants.serverUrl, {headers: { Authorization: `Bearer: ${token}` }});
      const start = moment(startDate).format("YYYY-MM-DD");
      const end = moment(endDate).format("YYYY-MM-DD");
      let query = `{ residentsVisits(dateStart: "${start}", dateEnd: "${end}", page: ${page}, search: "${search}") { total, visits { id, dueDate, quick, status, folio, permanence, kind, subject, user { name }, complement{companyName, details, driver, subject, vehicleColor, vehicleKind, vehiclePlates, visitKind, campus} } } }`;
      // Make request
      const req = await client.request(query);
      console.log(req.residentsVisits.visits[0].dueDate);
      // Update visits
      setVisits(req.residentsVisits.visits);
      setTotal(Math.ceil(req.residentsVisits.total / 20));
    } catch(err) {
      console.log(err);
      handleShowAlert("No se pudo obtener las visitas", "error");
    }
  }

  // Close alert bad company
  const handleCloseBadCompany = () => {
    setRedirectTo('/login');
    setRedirect(true);
  }

  // Handle filter dates
  const handleDateStart = (date) => {
    setStartDate(date.target.value);
  }

  const handleDateEnd = (date) => {
    setEndDate(date.target.value);
  }

  const handleDrawer = async (visit) => {
    setLoading(true);
    try {
      // Request options
      const client = new GraphQLClient(Constants.serverUrl, {headers: { Authorization: `Bearer: ${token}` }});
      let query = `{ residentVisit(id: ${visit.id}) {id, residentReport, validity, arriveDate, leaveDate, enterDate, user { name }, dueDate, quick, details,status, folio, permanence, kind, subject, plate { number, report, brand model classType year }, transport { name, plates}, visitor { name }, photos { url }, house { address }, insidents {reason}, complement{companyName, details, driver, subject, vehicleColor, vehicleKind, vehiclePlates, visitKind, campus} } }`;
      // Make request
      const req = await client.request(query);
      // Update visits
      setVisitDetails(req.residentVisit);
      setLoading(false);
      setVisitDetailsDrawer(true);
    } catch(err) {
      console.log(err);
      setLoading(false);
      handleShowAlert("No se pudo obtener la visita", "error");
    }
  }

  const closeDrawer = () => {
    setVisitDetailsDrawer(false);
  }

  const handleVisitStatus = (visit, status) => {
    setVisitDetails(visit);
    setOpenVisitStatus(true);
    setNewVisitStatus(status);
  }

  const updateStatus = async () => {
    setLoading(true);
    try{
      // Request options
      const client = new GraphQLClient(Constants.serverUrl, {headers: { Authorization: `Bearer: ${token}` }});
      const title = newVisitStatus === 'authorized' ? "Autorizaste la visita" : "Negaste la visita";
      const query = `mutation { residentStatusVisit(id: "${visitDetails.id}", status: "${newVisitStatus}") { id, status } }`;
      // Make request
      const req = await client.request(query);
      //console.log(req);
      // Update visits
      getVisits();
      handleShowAlert(title, "success");
      setLoading(false);
      setOpenVisitStatus(false);
    } catch(err) {
      console.log(err);
      setLoading(false);
      handleShowAlert("No se pudo obtener la visita", "error");
    }
  }

  const handleCloseNewVisit = () => {
    setNewVisitModal(false);
  }

  const createVisit = async (params) => {
    setLoading(true);
    try{
      // Request options
      const client = new GraphQLClient(Constants.serverUrl, {headers: { Authorization: `Bearer: ${token}` }});
      // Query base
      let queryParams = "";
      // Complement query
      if (params.vechicle === "Visita Vehícular") {
        queryParams = `date: "${params.fieldDate}", plates: "${params.fieldPlates}", companyName: "${params.fieldCompanyName}", driver: "${params.fieldDriver}", subject: "${params.fieldSubject}", details: "${params.fieldDetails}", vehicleKind: "${params.fieldVehicleKind}", vechicle: "${params.vechicle}", visitKind: "${params.visitKind}", visitColor: "${params.visitColor}", campus: "${params.fieldVisitCampus}"`;
      } else {
        queryParams = `date: "${params.fieldDate}", companyName: "${params.fieldCompanyName}", driver: "${params.fieldDriver}", subject: "${params.fieldSubject}", details: "${params.fieldDetails}", vechicle: "${params.vechicle}", visitKind: "${params.visitKind}", campus: "${params.fieldVisitCampus}"`;
      }

      const query = `mutation { createShortCompanyVisit(${queryParams}) { id, status } }`;
      // Make request
      const req = await client.request(query);
      // console.log(req);
      // Update visits
      getVisits();
      handleShowAlert("Tu visita ha sido creada", "success");
      setLoading(false);
      setNewVisitModal(false);
    } catch(err) {
      console.log(err);
      setLoading(false);
      handleShowAlert("No se pudo crear la visita", "error");
    }
  }

  const handlePage = (event, value) => {
    setPage(value - 1); 
    getVisits();
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <TopMenu rol={rol} />

      {/* Redirect */}
      {redirect ? <Navigate to={redirectTo} replace={true} /> : null}
      {/* Notification / Alert */}
      <Snackbar open={showAlert} autoHideDuration={5000} onClose={() => setShowAlert(false)}>
        <Alert onClose={() => setShowAlert(false)} severity={alertKind} sx={{ width: '100%' }}>{alertMessage}</Alert>
      </Snackbar>
      {/* Loagind */}
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}> <CircularProgress color="inherit" /> </Backdrop>
      {/* Alert for not company */}
      <Dialog
        open={openBadCompany}
        onClose={handleCloseBadCompany}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Esta aplicación es solo para empresas"}</DialogTitle>
      </Dialog>

      {/* Confirmation Visit Status */}
      <Dialog
        open={openVisitStatus}
        onClose={() => setOpenVisitStatus(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmación"}</DialogTitle>
        <DialogContent>
          <DialogContentText>¿Estas seguro de cambiar el status de la visita?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpenVisitStatus(false)}>Cancelar</Button>
          <Button onClick={() => updateStatus()}>Continuar</Button>
        </DialogActions>
      </Dialog>

      {/* Visit details */}
      <VisitDetails visit={visitDetails} open={visitDetailsDrawer} handleClose={closeDrawer} />

      {/* New visit */}
      <NewVisit residential={residential} open={newVisitModal} handleAlert={handleShowAlert} handleLoading={setLoading} handleClose={handleCloseNewVisit} handleSave={createVisit} />

      <Card elevation={3} style={{backgroundColor: '#FBFDFF'}}>
        <CardContent style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <TextField id="outlined-basic" label="Buscar" autoComplete='off' variant="outlined" size="small" sx={{mt: 1, mr: 2, width: '30%'}} onChange={(e) => setSearch(e.target.value)} />
          
          <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', minWidth: '50%'}}>
            <input style={dateInput} type="date" name="fieldDate" min={moment(today).format("YYYY-MM-DD")} onChange={(val) => handleDateStart(val)} />
            <input style={dateInput} type="date" name="fieldDate" min={moment(today).format("YYYY-MM-DD")} onChange={(val) => handleDateEnd(val)} />

            <Button variant="contained" sx={{ml: 2}} onClick={() => getVisits()}>Filtrar</Button>
            <Button variant="contained" sx={{ml: 1}} onClick={() => setNewVisitModal(true)}>Nueva visita</Button>
          </div>
        </CardContent>
      </Card>

      <Container component="main" sx={{mt: 3}}>
        {/* Visits list */}
        <List sx={{ width: '100%', bgcolor: 'background.paper', mt: 3 }}>
          {visits.map((visit) => {
            return (
            <ListItem key={`visit-${visit.id}`} alignItems="center" justifycontent="space-between" style={{padding: '25px 0px', borderBottom: '1px dotted #ddd'}}>
              <ListItemAvatar>
                <Avatar alt="Foto" src="/static/images/avatar/1.jpg" />
              </ListItemAvatar>

              <div style={{width: '30%'}}>
                <Typography sx={{ display: 'block' }} component="span" variant="h6" color="text.primary" style={{paddingLeft: 5, textTransform: 'capitalize'}}>
                  {visit.complement?.subject ? visit.complement?.subject : visit.subject}
                </Typography>

                <Chip size='small' variant={'outlined'} label={Constants.getStatus(visit.status)} color="success" style={{borderColor: Constants.getVisitColor(visit.status), color: Constants.getVisitColor(visit.status)}} />
              </div>

              <div style={{width: '30%', padding: 10}}>
                <Typography sx={{ display: 'block', mt: 1 }} component="span" variant="body1" color="text.primary" style={{paddingLeft: 5, textTransform: 'capitalize', display: 'flex', alignItems: 'center'}}>
                  <CalendarMonthIcon /> <span style={{marginLeft: 5}}>{moment(visit.dueDate).format("DD-MM-YYYY HH:mm")}</span>
                </Typography>
                <Typography sx={{ display: 'block', mt: 1 }} component="span" variant="body1" color="text.primary" style={{paddingLeft: 5, textTransform: 'capitalize', display: 'flex', alignItems: 'center'}}>
                  <SubtitlesIcon /> <span style={{marginLeft: 5}}>{visit.folio}</span>
                </Typography>
                <Typography sx={{ display: 'block', mt: 1 }} component="span" variant="body1" color="text.primary" style={{paddingLeft: 5, display: 'flex', alignItems: 'center'}}>
                <PersonIcon /> <span style={{marginLeft: 5}}>{visit.quick ? "Creada por guardia" : `Creada por ${visit.user?.name}`}</span>
                </Typography>

                <Stack direction="row" spacing={1} sx={{mt: 2}}>
                  <Button variant="contained" size='small' onClick={() => handleDrawer(visit)}>Detalles</Button>
                  {visit.status === "authorized" ? <Button variant="contained" size='small' style={{backgroundColor: '#21C5BB'}}><a href={`/visit-qr/${visit.id}`} target='blank' style={{color: '#fff'}}>QR</a></Button> : null}
                  {visit.status === "pending" ? <Button variant="contained" color='error' size='small' onClick={() => handleVisitStatus(visit, 'canceled')}>Cancelar</Button> : null}
                  {visit.status === "arrive" ? <Button variant="contained" color='success' size='small' onClick={() => handleVisitStatus(visit, 'authorized')}>Autorizar</Button> : null}
                  {visit.status === "arrive" ? <Button variant="contained" color='error' size='small' onClick={() => handleVisitStatus(visit, 'denied')}>Negar</Button> : null}
                </Stack>
              </div>

              <div style={{width: '30%', borderLeft: '1px solid #ddd', padding: 10}}>
                {visit.complement?.companyName ? 
                  <Typography sx={{ display: 'block' }} component="span" variant="body1" color="text.primary" style={{paddingLeft: 5, textTransform: 'capitalize', display: 'flex', alignItems: 'center'}}>
                    <BusinessIcon /> <span style={{marginLeft: 5}}>Empresa: {visit.complement.companyName}</span>
                  </Typography>
                : null}
                {visit.complement?.campus ? 
                  <Typography sx={{ display: 'block', mt: 1 }} component="span" variant="body1" color="text.primary" style={{paddingLeft: 5, textTransform: 'capitalize', display: 'flex', alignItems: 'center'}}>
                    <LocationOnIcon /> <span style={{marginLeft: 5}}>Sede: {visit.complement.campus}</span>
                  </Typography>
                : null}
                {visit.complement?.visitKind ? 
                  <Typography sx={{ display: 'block', mt: 1 }} component="span" variant="body1" color="text.primary" style={{paddingLeft: 5, textTransform: 'capitalize', display: 'flex', alignItems: 'center'}}>
                    <CameraFrontIcon /> <span style={{marginLeft: 5}}>{visit.complement.visitKind}</span>
                  </Typography>
                : null}
                {visit.complement?.driver ? 
                  <Typography sx={{ display: 'block', mt: 1 }} component="span" variant="body1" color="text.primary" style={{paddingLeft: 5, textTransform: 'capitalize', display: 'flex', alignItems: 'center'}}>
                    <PersonIcon /> <span style={{marginLeft: 5}}>Conductor: {visit.complement.driver}</span>
                  </Typography>
                : null}
                {visit.complement?.vehicleKind ? 
                  <Typography sx={{ display: 'block', mt: 1 }} component="span" variant="body1" color="text.primary" style={{paddingLeft: 5, textTransform: 'capitalize', display: 'flex', alignItems: 'center'}}>
                    <DriveEtaIcon /> <span style={{marginLeft: 5}}>{visit.complement.vehicleKind}</span> 
                    <span style={{marginLeft: 7}}>| Placas: {visit.complement.vehiclePlates}</span> 
                    <span style={{marginLeft: 7, display: 'flex'}}>| Color: <CircleIcon style={{color: visit.complement.vehicleColor}} /></span>
                  </Typography>
                : null}
                {visit.complement?.details ? 
                  <Typography sx={{ display: 'block', mt: 1 }} component="span" variant="body1" color="text.primary" style={{paddingLeft: 5, textTransform: 'capitalize', display: 'flex', alignItems: 'center'}}>
                    <FindInPageIcon /> <span style={{marginLeft: 5}}>{visit.complement.details}</span>
                  </Typography>
                : null}
              </div>
            </ListItem>
            )
          })}
        </List>

        <div style={{display: 'flex', justifyContent: 'center', margin: '20px 0px'}}>
          <Pagination count={total} page={page + 1} onChange={handlePage} />
        </div>
      </Container>
    </ThemeProvider>
  )
}
