import * as React from 'react';
import { useParams, Navigate } from 'react-router-dom';
// MUI
import { Container, Snackbar, Alert, Typography } from '@mui/material';
// Requests
import { GraphQLClient } from 'graphql-request';
import Constants from '../Constants';
// QR
import QRCode from "react-qr-code";
// Images
import logo from '../assets/logo.png';

// Mount var
let mount = false;
let params = null;
// Session data
let token = null;

export default function VisitQr(props) {
  const [redirect, setRedirect] = React.useState(false);
  const [redirectTo, setRedirectTo] = React.useState('/login');
  // Alert
  const [alertMessage, setAlertMessage] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertKind, setAlertKind] = React.useState("success");
  // Visit
  const [visitDetails, setVisitDetails] = React.useState({});
  // Residential
  const [residential, setResidential] = React.useState({});
  // Get url params
  params = useParams();

  React.useEffect(() => {
    if (!mount && params) {
      token = sessionStorage.getItem('token');
      // Redirect to login
      if (!token) {
        setRedirectTo('/login');
        setRedirect(true);
      } 
      // Get residential
      getResidential();
      // Get visit
      getVisit(params.id);
      // Update mount
      mount = true;
    }
  }, []);

  // Show alert
  const handleShowAlert = (message, kind) => {
    setAlertMessage(message);
    setShowAlert(true);
    setAlertKind(kind);
  }

  // Get residential
  const getResidential = async () => {
    try {
      // Request options
      const client = new GraphQLClient(Constants.serverUrl, {headers: { Authorization: `Bearer: ${token}` }});
      const query = `{ myResidential { name, channel, qrNumeric } }`;

      // Make request
      const req = await client.request(query);
      // Update residential
      setResidential(req.myResidential);
    } catch(err) {
      // console.log(err);
      handleShowAlert("No se pudo obtener información del residencial", "error");
    }
  }

  // Get visit
  const getVisit = async (id) => {
    try {
      // Request options
      const client = new GraphQLClient(Constants.serverUrl, {headers: { Authorization: `Bearer: ${token}` }});
      let query = `{ residentVisit(id: ${id}) {id, residentReport, validity, arriveDate, leaveDate, enterDate, user { name }, dueDate, quick, details,status, folio, permanence, kind, subject, plate { number, report, brand model classType year }, transport { name, plates}, visitor { name }, photos { url }, house { address }, insidents {reason}, complement{companyName, details, driver, subject, vehicleColor, vehicleKind, vehiclePlates, visitKind} } }`;
      // Make request
      const req = await client.request(query);
      // Update visits
      setVisitDetails(req.residentVisit);
      // Print
      setTimeout(() => {
        window.print();
      }, 1000);
    } catch(err) {
      // console.log(err);
      handleShowAlert("No se pudo obtener información de la visita", "error");
    }
  }

  return (
    <Container component="main" style={{height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      {/* Redirect */}
      {redirect ? <Navigate to={redirectTo} replace={true} /> : null}
      {/* Notification / Alert */}
      <Snackbar open={showAlert} autoHideDuration={5000} onClose={() => setShowAlert(false)}>
        <Alert onClose={() => setShowAlert(false)} severity={alertKind} sx={{ width: '100%' }}>{alertMessage}</Alert>
      </Snackbar>

      <img src={logo} className="App-logo" alt="Visitapp" style={{width: 200, marginBottom: 20}} />
      <Typography sx={{ display: 'block' }} component="span" variant="h6" color="text.primary" style={{textTransform: 'capitalize'}}>
        Empresa {residential.name}
      </Typography>
      <Typography sx={{ display: 'block' }} component="span" variant="h6" color="text.primary" style={{textTransform: 'capitalize'}}>
        Departamento: {visitDetails?.house?.address} | Anfitrion: {visitDetails?.user?.name}
      </Typography>
      <Typography sx={{ display: 'block' }} component="span" variant="body1" color="text.primary">
        Ese código solo puede ser usa una única vez.
      </Typography>

      <QRCode value={`${visitDetails.folio}-${residential.channel}`} style={{marginTop: 20}} />
    </Container>
  )
}