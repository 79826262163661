import * as React from 'react';
import { Modal, Box, Card, CardContent, Typography, Stepper, Step, StepLabel, Button, TextField, Select, InputLabel, MenuItem } from '@mui/material';
// Dates
import moment from 'moment';
// Color picker
import { CirclePicker } from 'react-color';
// Icons
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

// Styles
const modalStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  height: '80vh',
  borderRadius: 5,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  borderWidth: 0,
};

const modalContainerStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  overflowY: 'auto',
  height: '80vh',
};

const dateInput = {
  font: 'inherit',
  boxSizing: 'content-box',
  background: 'none',
  width: '50%',
  border: '1px solid #ccc',
  borderRadius: 5,
  padding: '10px 20px',
  marginTop: 10,
}

const timeInput = {
  font: 'inherit',
  boxSizing: 'content-box',
  background: 'none',
  width: '35%',
  border: '1px solid #ccc',
  borderRadius: 5,
  padding: '10px 20px',
  marginLeft: 5
}

const steps = ['Tipo de acceso', 'Tipo de visita', 'Datos del visitante'];
const transports = ['Visita Vehícular', 'Visita Peatonal'];
const colors = ["#f81101", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#ffeb3b", "#ffc107", "#ff9800", "#795548", "#000000", "#607d8b",  "#ABB8C3", "#f1f1f1"];
const today = new Date();
const skipped = new Set();

export default function NewVisit(props) {
  const visitTypes = props.residential?.staffVisitTypesList ? props.residential.staffVisitTypesList?.split(",") : [];
  const vehicleTypes = props.residential?.timelineVehicleTypeList ? props.residential.timelineVehicleTypeList?.split(",") : [];
  const campus = props.residential?.companyCampus ? props.residential.companyCampus?.split(",") : [];
  const tz = props.residential?.timezoneHours ? props.residential.timezoneHours : 6;
  const [activeStep, setActiveStep] = React.useState(0);
  const [vechicle, setVechicle] = React.useState('');
  const [visitKind, setVisitKind] = React.useState('');
  const [visitColor, setVisitColor] = React.useState('');
  const [visitCampus, setVisitCampus] = React.useState(campus && campus[0] ? campus[0].trim() : '');
  const [vehicleKind, _setVehicleKind] = React.useState(vehicleTypes && vehicleTypes[0] ? vehicleTypes[0].trim() : '');

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const getVisitTransportShade = (index) => {
    // validate index
    let kindNumber = index + 1;
    if (kindNumber > 4) {
      kindNumber = kindNumber % 4;
    }

    // Get color
    switch(kindNumber) {
      case 1:
        return '#21C5BB';
      case 2:
        return '#14BAFA';
      case 3:
        return '#12DE6E';
      case 4:
        return '#197CD1';
      case 0:
        return '#12DBDE';
      default:
        return '#21C5BB';
    }
  }

  const getVisitKindShade = (index) => {
    // validate index
    let kindNumber = index + 1;
    if (kindNumber > 4) {
      kindNumber = kindNumber % 4;
    }

    // Get color
    switch(kindNumber) {
      case 1:
        return '#163A6B';
      case 2:
        return '#327EEB';
      case 3:
        return '#374C6B';
      case 4:
        return '#2763B8';
      case 0:
        return '#105f94';
      default: 
      return '#163A6B';
    }
  }

  // Wizard step vechicle
  const handleVehicle = (transport) => {
    setVechicle(transport);
    setActiveStep(1);
  }

  // Wizard step visit kind
  const handleVisitKind = (kind) => {
    setVisitKind(kind);
    setActiveStep(2);
  }

  // Wizard cancel
  const handleCancel = () => {
    props.handleClose();
    setActiveStep(0);
  }

  // Wizard step back
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  }

  // Wizard handle colors
  const handleColor = (e) => {
    setVisitColor(e.hex);
  }


  // Submit login form
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const formData = new FormData(event.currentTarget);
      // Peatonal Visit
      if (vechicle === 'Visita Peatonal') {
        // Peatonal visit
        // Set form data
        let fieldDate = formData.get('fieldDate');
        const fieldTime = formData.get('fieldTime');
        const fieldCompanyName = formData.get('fieldCompanyName');
        const fieldDriver = formData.get('fieldDriver');
        const fieldSubject = formData.get('fieldSubject');
        const fieldDetails = formData.get('fieldDetails');
        const fieldVisitCampus = formData.get('fieldVisitCampus');
  
        // Validate required fields
        if (!fieldDate || !fieldCompanyName || !fieldDriver || !fieldSubject || !fieldVisitCampus) {
          return props.handleAlert("Los campos con * son requeridos.", "error");
        }

        // Send form data to visits
        const parseDate = `${fieldDate}T${fieldTime}:00.000+00:00`;
        let tzDatetime = Date.parse(parseDate);
        tzDatetime = moment(tzDatetime).add(tz*2, 'hours');
        const tzDate = `${tzDatetime.format('YYYY-MM-DD')}T${tzDatetime.format('HH:mm')}:00+00`;

        props.handleSave({fieldDate: tzDate, fieldCompanyName: fieldCompanyName, fieldDriver: fieldDriver, fieldSubject: fieldSubject, fieldDetails: fieldDetails, vechicle: vechicle, visitKind: visitKind, visitColor: visitColor, fieldVisitCampus: fieldVisitCampus});
        setActiveStep(0);
      } else {
        // Vehicular Visit
        // Set form data
        let fieldDate = formData.get('fieldDate');
        const fieldTime = formData.get('fieldTime');
        const fieldCompanyName = formData.get('fieldCompanyName');
        const fieldDriver = formData.get('fieldDriver');
        const fieldSubject = formData.get('fieldSubject');
        const fieldDetails = formData.get('fieldDetails');
        const fieldVisitCampus = formData.get('fieldVisitCampus');
        const fieldVehicleKind = formData.get('fieldVehicleKind');
        const fieldPlates = formData.get('fieldPlates');
  
        // Validate required fields
        if (!fieldDate || !fieldCompanyName || !fieldDriver || !fieldSubject || !visitColor || !fieldPlates || !fieldVisitCampus) {
          return props.handleAlert("Los campos con * son requeridos.", "error");
        }

        // Send form data to visits
        const parseDate = `${fieldDate}T${fieldTime}:00.000+00:00`;
        let tzDatetime = Date.parse(parseDate);
        tzDatetime = moment(tzDatetime).add(tz*2, 'hours');
        const tzDate = `${tzDatetime.format('YYYY-MM-DD')}T${tzDatetime.format('HH:mm')}:00+00`;

        props.handleSave({fieldDate: tzDate, fieldCompanyName: fieldCompanyName, fieldDriver: fieldDriver, fieldSubject: fieldSubject, fieldDetails: fieldDetails, fieldVehicleKind: fieldVehicleKind, fieldPlates: fieldPlates, vechicle: vechicle, visitKind: visitKind, visitColor: visitColor, fieldVisitCampus: fieldVisitCampus});
        setActiveStep(0);
      }
    } catch(err) {
      // console.log(err);
      // Send error to visits 
      props.handleAlert("Hubo un problema al crear tu visita.", "error");
    }
  }

  return ( 
    <Modal
      open={props.open}
      onClose={handleCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyles}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        <div style={modalContainerStyles}> 
          <div style={{height: 1}}></div>

          {/* Visit transport step */}
          {activeStep === 0 ?
          <Box>
            {transports.map((trans, index) => {
              return (
                <Card key={`trans-${index}`} sx={{ mt: 2 }} elevation={2} onClick={() => handleVehicle(trans)} style={{cursor: 'pointer', color: '#fff', backgroundColor: getVisitTransportShade(index)}}>
                  <CardContent style={{display: 'flex', justifyContent: 'space-between', padding: 20}}>
                    <Typography variant="h5" component="div" style={{textTransform: 'capitalize'}}>{trans}</Typography>
                    <KeyboardArrowRightIcon style={{fontSize: '2.5em'}} />
                  </CardContent>
                </Card>
              )
            })}
          </Box>
          :null}

          {/* Visit type step */}
          {activeStep === 1 ?
          <Box>
            {visitTypes.map((type, index) => {
              return (
                <Card key={`type-${index}`} sx={{ mt: 2 }} elevation={2} onClick={() => handleVisitKind(type.trim())} style={{cursor: 'pointer', border: '1px solid #ddd', color: '#fff', backgroundColor: getVisitKindShade(index)}}>
                  <CardContent style={{display: 'flex', justifyContent: 'space-between', padding: 20}}>
                    <Typography variant="h5" component="div" style={{textTransform: 'capitalize'}}>{type.trim()}</Typography>
                    <KeyboardArrowRightIcon style={{fontSize: '2.5em'}} />
                  </CardContent>
                </Card>
              )
            })}
          </Box>
          :null}

          {activeStep === 2 ?
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{pt: 4}}>
            <div style={{marginTop: 15}}>Fecha de la visita <span style={{color: "#ef5350"}}>*</span></div>
            <input style={dateInput} type="date" name="fieldDate" min={moment(today).format("YYYY-MM-DD")} />
            <input style={timeInput} type="time" name="fieldTime" min={moment(today).format("HH:MM")} />
            
            <div style={{marginTop: 15}}>Nombre de la empresa <span style={{color: "#ef5350"}}>*</span></div>
            <TextField name="fieldCompanyName" label="Empresa" variant="outlined" sx={{width: '100%', mt: 1}} />

            <div style={{marginTop: 15}}>{vechicle === 'Visita Peatonal' ? "Nombre del visitante" : "Nombre del conductor"} <span style={{color: "#ef5350"}}>*</span></div>
            <TextField name="fieldDriver" label={vechicle === 'Visita Peatonal' ? "Visitante" : "Conductor"} variant="outlined" sx={{width: '100%', mt: 1}} />
            
            <div style={{marginTop: 15}}>Asunto de la visita <span style={{color: "#ef5350"}}>*</span></div>
            <TextField name="fieldSubject" label="Asunto" variant="outlined" sx={{width: '100%', mt: 1}} />
            
            <div style={{marginTop: 15}}>Detalles de la visita</div>
            <TextField name="fieldDetails" label="Detalles" variant="outlined" sx={{width: '100%', mt: 1}} />
            
            <div style={{marginTop: 15}}>Sede <span style={{color: "#ef5350"}}>*</span></div>
            <Select
              name="fieldVisitCampus"
              labelId='campus-label' 
              required 
              defaultValue={visitCampus}
              onChange={(e) => console.log(e)} 
              sx={{width: '100%', mt: 1}}
            >
              {campus.map((type) => {
                return (<MenuItem key={type.trim()} value={type.trim()} style={{textTransform: 'capitalize'}}>{type.trim()}</MenuItem>);
              })}
            </Select>
            
            {vechicle !== 'Visita Peatonal' ? 
            <>
              <div style={{marginTop: 15}}>Placas del vehículo <span style={{color: "#ef5350"}}>*</span></div>
              <TextField name="fieldPlates" label="ABS291" variant="outlined" sx={{width: '100%', mt: 1}} />
              
              <div style={{marginTop: 15}}>Tipo de vehículo <span style={{color: "#ef5350"}}>*</span></div>
              <Select
                name="fieldVehicleKind"
                labelId='vehicle-type-label' 
                required 
                defaultValue={vehicleKind}
                onChange={(e) => console.log(e)} 
                sx={{width: '100%', mt: 1}}
              >
                {vehicleTypes.map((type) => {
                  return (<MenuItem key={type.trim()} value={type.trim()} style={{textTransform: 'capitalize'}}>{type.trim()}</MenuItem>);
                })}
              </Select>

              <div style={{marginTop: 8}}>
                <div style={{marginTop: 15}}>Color del vehículo <span style={{color: "#ef5350"}}>*</span></div>
                <div style={{display: 'flex', justifyContent: 'center'}}><CirclePicker onChange={handleColor} colors={colors} /></div>
              </div>
            </>
            : null}
            
            <div style={{textAlign: 'center', marginTop: '2rem', paddingBottom: 30, borderBottom: '1px solid #ddd'}}>
              <Button variant="contained" type="submit" sx={{mr: 1}}>Confirmar</Button>
            </div>
          </Box>
          :null}

          {/* Cancel new visit */}
          <div style={{display: 'flex', justifyContent: 'center', margin: '1rem 0'}}>
            {activeStep >= 1 ? 
              <Button variant="outlined" onClick={() => handleBack()} sx={{mr: 1}}>Atras</Button>
            : null}
            <Button variant="outlined" color="error" onClick={() => handleCancel()}>Cancelar</Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
}
