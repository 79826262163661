const Constants = {
  loginUrl: 'https://caseta.visitapp.io/api/v1',
  serverUrl: 'https://caseta.visitapp.io/api/v2-7-0',
  getVisitColor: (status) => {
    switch (status) {
      case "pending":
        return '#FC8842';
      case "enter":
        return '#4E9EFC';
      case "denied":
        return '#FC695B';
      case "authorized":
        return '#2BCFA3';
      case "arrive":
        return '#284F45';
      case "leave":
        return '#FC695B';
      case "canceled":
        return '#333';
      case "house_in":
        return '#4E9EFC';
      case "house_out":
        return '#FC695B';
      case "inside":
        return '#4E9EFC';
      case "outside":
        return "#FC695B";
      default:
        return '#30B3E6';
    }
  },
  getStatus: (status) => {
    switch (status) {
      case "pending":
        return 'Pendiente de autorización';
      case "enter":
        return 'Dentro del residencial';
      case "denied":
        return 'Negada';
      case "authorized":
        return 'Autorizada';
      case "arrive":
        return 'En caseta';
      case "leave":
        return 'Fuera del residencial';
      case "canceled":
        return 'Cancelada';
      case "house_in":
        return 'Dentro del edificio';
      case "house_out":
        return 'Fuera del edificio';
      case "inside":
        return 'Dentro mega-residencial';
      case "outside":
        return 'Fuera mega-residencial';
      default:
        break;
    }
  },
  getKind: (visit) => {
    switch (visit.kind) {
      case "family":
        return visit.visitor.name.capitalize()
      case "employee":
        return "Entrada de empleado";
      default:
        return "Visita externa";
    }
  }
};

export default Constants;
